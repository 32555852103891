import React from 'react';
import { Routes, Route } from "react-router-dom";

import './website.css';

import Home from './pages/home';
// import WebsiteBuilder from './pages/webisteBuilder';
import NotFound from './pages/notFound';

const App = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      {/* <Route path='/websitebuilder' element={<WebsiteBuilder />} /> */}
      <Route path='*' element={<NotFound />} />
    </Routes >
  );
};

export default App;
