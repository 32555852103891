// import me_photo from "../images/me-photo-compressed.png"
import me_photo from "../images/me-photo.png"
import linkedin_icon from "../images/icons/linkedin_icon.svg"
import cv_icon from "../images/icons/cv_icon.svg"
import upwork_icon from "../images/icons/upwork.svg"

const LinkedInIcon = (): JSX.Element => {
    return (
        < a href="https://www.linkedin.com/in/viren-samani/" title="LinkedIn" >
            <img className="Icon" src={linkedin_icon}
                alt="LinkedIn Icon" />
        </a >
    );
};

const CVIcon = (): JSX.Element => {
    return (
        < a href="https://www.amazon.com/clouddrive/share/1S2x0V4MqR6tO2BK9lPYS3dqaabdAoeyAXAszomf5NA" title="CV" >
            <img className="Icon" src={cv_icon}
                alt="CV Icon" />
        </a >
    );
};

const UpworkIcon = (): JSX.Element => {
    return (
        < a href="https://www.upwork.com/freelancers/~01ce3b242382ce891e" title="Upwork" >
            <img className="Icon" src={upwork_icon}
                alt="UpWork Icon" />
        </a >
    );
};

const Home = (): JSX.Element => {
    return (
        <div className="App">
            {/* Home Section */}
            <div className="Home section" id="home"
                style={{
                    backgroundColor: "#F9F9F9"
                }}>

                <div className="Header">

                </div>

                <div className="body">
                    <div>
                        <img className="Photo-holder" src={me_photo}
                            alt="Photo of me" />

                    </div>

                    {/* <div className="Name-heading" style={{ color: "#4F6367" }}> */}
                    {/* <div className="Name-heading" style={{ color: "#FE5F55" }}> */}
                    <div className="Name-heading" style={{ color: "#509490" }}>
                        Viren Samani
                    </div>

                    <div className="Subheading" style={{ color: "#3A6B84" }}>
                        Optimistic, Driven & Forward-Thinking
                    </div>
                </div>

                <div className="Footer">
                    <div className="Icons-container">
                        {LinkedInIcon()}
                        {CVIcon()}
                        {UpworkIcon()}
                    </div>
                </div>


            </div>
        </div>

    );
};

export default Home;

// Colour Scheme
// Blue: #1B3C73
// Orange: #FF9B5A
// Red: #E86345
// Yellow: #F0D572
// Off-white: #ECECD5
