const NotFound = (): JSX.Element => {

    const FriendlyGhost = (): JSX.Element => {
        return (
            <div className="ghost__container">
                <div className="container__elements">
                    <div className="ghost">
                        <div className="ghost__eyes"></div>
                        <div className="ghost__feet">
                            <div className="ghost__feet-foot"></div>
                            <div className="ghost__feet-foot"></div>
                            <div className="ghost__feet-foot"></div>
                            <div className="ghost__feet-foot"></div>
                        </div>
                    </div>
                    <div className="shadow"></div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <header className="NotFound-header">
                {FriendlyGhost()}
                <p className="Subheading-1"
                    style={{
                        color: "white"
                    }}>
                    Looks like you've found a page that doesn't exist
                </p>

                <a href="/"
                    style={{
                        textDecoration: "none",
                        color: "black",
                        fontSize: "1.2em",
                        textAlign: "center"
                    }}
                >
                    <div className='Message-box'>
                        Click here to go back to safety
                    </div>
                </a>

            </header>
        </div >
    );
};

export default NotFound;
